<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 md10 offset-md1 lg8 offset-lg2>
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-card-text>
            <div>{{ route }}</div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  computed: {
    title () {
      return this.$route.path
    },
    route () {
      return this.$route.path
    }
  }
}
</script>
